.app__gallery {
    flex-direction: row;
    background: var(--color-black);
    padding: 4rem 0 4rem 6rem;
}

.app__gallery-content {
    display: flex; 
    justify-content: space-between;
}

.content__left {
    flex: 1; 
    padding: 0 1rem; 
}

.content__right {
    flex: 1; 
    padding: 0 1rem; 
    margin-bottom: 10rem;
    margin-top: 5rem;
}

input, textarea {
    width: 100%;
    height: 50px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #AAAAAA;
    border-radius: 4px;
}
  
textarea {
    resize: vertical;
    height: 100px;
}

input:focus, textarea:focus {
    outline-color: #DCCA87;
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .content__right {
        margin-top: 1rem; /* Adjust the top margin of the form on smaller screens */
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 2rem;
    }  
}
